.Blog {
  width: 95%;
  height: 65vh;
  border-radius: 3px;
  -webkit-box-shadow: -1px 6px 20px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -1px 6px 20px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 6px 20px -4px rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-self: center;
  position: relative;
  background-color: var(--light-navy);
}
.Blog:hover {
  animation-name: moveCard;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}
@keyframes moveCard {
  0% {
    margin-top: 0px;
  }
  100% {
    margin-top: -10px;
  }
}

.cardImage {
  width: 100%;
  height: 60%;
  border-radius: 5px 5px 0px 0px;
}
.Blog img {
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0px 0px;
}
.Blog a {
  color: var(--green);
  text-decoration: none;
}
.cardOverlay {
  top: 0;
  width: 100%;
  height: 60%;
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  opacity: 0.3;
}
.cardText {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1% 4%;
}
.cardText > div {
  height: 32%;
  display: flex;
  align-items: center;
}
.cardText > div .headingLink {
  color: var(--white);
  font-size: 21px;
}
.cardText > div .headingLink:hover {
  color: var(--green);
}
.borderBottom {
  border-bottom: 1px solid var(--lightest-navy);
}

.cardText > .cardDescription > p {
  color: var(--light-slate);
}
.cardBottom {
  display: flex;
  justify-content: space-between;
  font-family: var(--secondary-font);
}
.cardBottomLinks {
  display: flex;
  font-size: 22px;
}
.cardBottomLinks > p {
  font-size: 15px;
  color: var(--green);
}
.cardBottom button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: var(--green);
  background-color: var(--navy-color);
  font-family: var(--secondary-font);
  cursor: pointer;
}
.cardBottom button:hover {
  color: var(--light-slate);
}
@media screen and (max-width: 850px) {
  .Blog {
    width: 100%;
    margin: 10px 0;
  }

  @keyframes moveCard {
    0% {
      margin-top: 10px;
    }
    100% {
      margin-top: 10px;
    }
  }
}
