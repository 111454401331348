.Title {
  width: 100%;
  height: auto;
  padding: 1% 0;
  font-family: var(--secondary-font);
  color: var(--lightest-slate);
  font-size: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Title > .headingBorder1 {
  border-bottom: 1px solid var(--green);
  width: 10%;
  margin-top: 3px;
}
.Title > .headingBorder2 {
  border-bottom: 1px solid var(--green);
  width: 6%;
  margin-top: 5px;
}
@media screen and (max-width: 850px) {
  .Title {
    height: 20%;
    font-size: 22px;
    padding-top: 10px;
  }
  .Title > .headingBorder1 {
    width: 30%;
  }
  .Title > .headingBorder2 {
    width: 20%;
  }
}
