.Projects {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  padding: 12vh 13% 0 13%;
}

.ProjectsInner {
  padding: 30px 0;
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 30px;
}
@media screen and (max-width: 850px) {
  .Projects {
    padding: 10vh 5% 0 5%;
  }

  .ProjectsInner {
    padding: 30px 0;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
}
