.GetInTouch {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.GetInTouch > .whatNext {
  color: var(--green);
  font-family: var(--secondary-font);
}
.GetInTouch > h1 {
  color: var(--lightest-slate);
  font-size: 3.8em;
  padding: 2% 0;
}
.GetInTouch > .aboutWhatNext {
  font-size: 18px;
  width: 60%;
  font-family: var(--primary-font);
  line-height: 25px;
  color: var(--light-slate);
  font-weight: light;
  padding-bottom: 3%;
}

@media screen and (max-width: 850px) {
  .GetInTouch {
    height: 80vh;
  }
  .GetInTouch > h1 {
    font-size: 3em;
  }
  .GetInTouch > .aboutWhatNext {
    font-size: 18px;
    width: 90%;
  }
}
