.Project {
  width: 95%;
  height: 55vh;
  background-color: var(--light-navy);
  background-color: #172a45;
  border-radius: 4px;
  box-shadow: -1px 3px 8px -4px rgba(0, 0, 0, 0.48);
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.Project:hover {
  animation-name: moveCard;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}
@keyframes moveCard {
  0% {
    margin-top: 0px;
  }
  100% {
    margin-top: -10px;
  }
}
.ProjectIcons,
.languages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 20%;
  line-height: 25px;
}
.Project a {
  text-decoration: none;
  color: var(--light-slate);
}
.Project a:hover {
  color: var(--green);
}
.ProjectIcons > div:first-child > p {
  font-size: 40px;
  font-weight: lighter;
  color: var(--green);
}
.ProjectIcons > div {
  display: flex;
  width: auto;
  height: 100%;
  align-items: center;
}
.ProjectIcons > div > p {
  font-size: 24px;
}
.ProjectIcons > div > p > a {
  font-size: 24px;
  margin-left: 15px;
}
.description {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 60%;
  line-height: 25px;
  justify-content: center;
  font-family: var(--primary-font);
}
.description > div > h3 {
  padding-top: 0px;
  color: var(--lightest-slate);
}
.description > div > p {
  color: var(--light-slate);
  font-size: 15px;
}
.languages > p {
  font-size: 12px;
  color: var(--light-slate);
  font-family: var(--secondary-font);
}
@media screen and (max-width: 850px) {
  .Project {
    width: 100%;
  }

  @keyframes moveCard {
    0% {
      margin-top: 0px;
    }
    100% {
      margin-top: 0px;
    }
  }
  .ProjectIcons,
  .languages {
    width: 90%;
    height: 20%;
  }

  .description {
    width: 90%;
    height: 60%;
  }
}
