.HomeProject {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10vh 0;
}
.HomeProject a {
  text-decoration: none;
}
.HomeProject > .textContent {
  width: 80%;
  font-weight: light;
  color: var(--light-slate);
  font-size: 18px;
  line-height: 30px;
}
@media screen and (max-width: 850px) {
  .HomeProject {
    height: 75vh;
    padding: 5vh 0;
  }

  .HomeProject > .textContent {
    width: 100%;
    font-size: 17px;
    line-height: 30px;
  }
}
