.LandingPage {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  height: 88vh;
  background-color: var(--navy-color);
}
.LandingPageContent {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.headings > p {
  font-family: var(--secondary-font);
  font-size: 15px;
  color: var(--green);
}
.headings > .myName {
  font-family: var(--primary-font);
  font-size: 3.8em;
  color: var(--lightest-slate);
}
.headings > .tagLine {
  font-family: var(--primary-font);
  font-size: 3.8em;
  color: var(--light-slate);
}
.LandingPageContent > .aboutMe {
  font-size: 18px;
  width: 50%;
  font-family: var(--primary-font);
  line-height: 25px;
  color: var(--light-slate);
  font-weight: light;
}

.LandingPageContent > a {
  width: 160px;
}

@media screen and (max-width: 850px) {
  .LandingPage {
    font-size: 15px;
    height: 90vh;
  }
  .LandingPageContent {
    height: 78%;
  }

  .headings > .myName {
    font-size: 3em;
  }
  .headings > .tagLine {
    font-size: 3em;
    padding-top: 15px;
  }
  .LandingPageContent > .aboutMe {
    font-size: 16px;
    width: 90%;
    line-height: 25px;
  }

  .LandingPageContent > a {
    width: 140px;
  }
}
