.Home {
  width: 100%;
  height: auto;
  padding: 12vh 13% 0 13%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--navy-color);
  color: var(--white);
}
@media screen and (max-width: 850px) {
  .Home {
    padding: 10vh 5% 0 5%;
  }
}
