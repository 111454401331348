.Button {
  border: 1px solid var(--green);
  padding: 16px 5px;
  width: 160px;
  text-align: center;
  border-radius: 3px;
  font-family: var(--secondary-font);
  font-size: 15px;
  text-decoration: none;
  color: var(--green);
}

.Button:hover {
  animation-name: slowMotion;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}
@keyframes slowMotion {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: var(--lightest-navy);
  }
}
@media screen and (max-width: 850px) {
  .Button {
    padding: 14px 4px;
    width: 140px;
    font-size: 13px;
  }
}
