.Navbar {
  width: 100%;
  height: 10vh;
  background-color: var(--navy-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.newNavbar {
  width: 100%;
  height: 10vh;
  background-color: var(--navy-color);
  color: #000;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.Navbar > h1 img {
  width: 42px;
  display: block;
}

.Links {
  display: flex;
}
.linkNumber {
  color: var(--green);
}
.Links a {
  padding: 10px 20px;
  text-decoration: none;
  font-family: var(--secondary-font);
  font-size: 15px;
  color: var(--light-slate);
}
.Links a:hover {
  color: var(--green);
}
.activeLink {
  border-bottom: 1px solid var(--green);
}
.burgerMenuBar {
  display: none;
}
@media screen and (max-width: 850px) {
  .Navbar {
    /* width: 100%;
    height: 10vh;
    background-color: var(--navy-color);
    display: flex;
    justify-content: space-between;
    align-items: center; */
    padding: 0 10px;
    /* position: fixed;
    top: 0;
    z-index: 1000; */
  }
  .newNavbar {
    /* width: 100%;
    height: 10vh;
    background-color: var(--navy-color);
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center; */
    padding: 0 10px;
    /* position: fixed;
    top: 0;
    z-index: 1000;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); */
  }

  /* .Navbar > h1 img {
    width: 42px;
    display: block;
  } */

  .Links {
    display: none;
  }
  /* .linkNumber {
    color: var(--green);
  }
  .Links a {
    padding: 10px 20px;
    text-decoration: none;
    font-family: var(--secondary-font);
    font-size: 15px;
    color: var(--light-slate);
  }
  .activeLink {
    border-bottom: 1px solid var(--green);
  } */
  .burgerMenuBar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 40px;
    height: 60%;
    cursor: pointer;
  }
  .burgerMenuBar > div {
    border-top: 2px solid var(--green);
    border-radius: 1px;
    height: 30%;
  }
  .burgerMenuBar > .slice1 {
    width: 100%;
    margin-top: 10%;
  }
  .burgerMenuBar > .slice2 {
    width: 82%;
  }
  .burgerMenuBar > .slice3 {
    width: 61%;
  }
}
