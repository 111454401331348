.Post {
  width: 100%;
  padding: 12vh 13% 0 13%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  color: var(--white);
  font-weight: lighter;
  list-style-position: inside;
}
.innerPost {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Post a {
  text-decoration: none;
  color: var(--green);
}
.Post li {
  padding: 15px 0 5px 0;
}
.Post img {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.PostCoverImage {
  border-radius: 5px 5px 0 0;
}

.Post button {
  padding: 4px 6px;
  border: none;
  color: var(--navy-color);
  margin: 15px 3px 3px 3px;
  border-radius: 3px;
}
.Post code {
  display: inline-block;
  width: 100%;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background-color: var(--light-black);
  padding: 15px;
  margin: 10px 0;
  border-radius: 4px;
  color: (--white);
}
.Post .tagButton1 {
  background-color: #f7df1e;
}
.Post .tagButton2 {
  background-color: #59c4e2;
}
.Post .tagButton3 {
  background-color: #3d8836;
}
.Post .tagButton4 {
  background-color: #562765;
}
.PostInfo {
  width: 5%;
  height: 88vh;
  top: 12vh;
  position: sticky;
}
.PostInnerDiv {
  width: 95%;
  height: auto;
  background-color: var(--navy-color);
  border-radius: 5px 5px 0 0;
}
.PostContent {
  padding: 5% 0;
}
.PostContent img {
  margin: 10px auto;
  border-radius: 5px;
}
.WriterDetails {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 25px 0;
}

.WriterDetails img {
  border-radius: 50%;
  width: 35px;
}

.WriterDetails > div {
  display: flex;
  align-items: center;
}
.WriterDetails p {
  font-size: 15px;
}
.userName {
  padding-left: 7px;
}
.icon {
  padding: 5px 15px 0px 4px;
}

.PostText h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 18px 0 10px 0;
  color: var(--light-slate);
}
.PostText h1,
h2,
h3,
b,
i {
  color: var(--light-slate);
}
.PostText blockquote {
  padding: 20px 0 20px 10px;
  border-left: 3px solid #747c85;
  margin: 10px 0;
}
@media screen and (max-width: 850px) {
  .Post {
    padding: 10vh 0 0 0;
    font-size: 18px;
    line-height: 30px;
  }

  .Post a {
    color: var(--green);
  }
  .Post li {
    padding: 10px 0 5px 0;
  }
  .Post img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .Post code {
    padding: 10px 5px;
  }

  .PostInfo {
    /* top: 10vh; */
    display: none;
  }
  .PostInnerDiv {
    width: 100%;
  }
  .Post .PostCoverImage {
    background-color: var(--lightest-slate);
    width: 100%;
    border-radius: 0px;
  }
  .Post .PostCoverImage > img {
    max-width: 100%;
    border-radius: 0px;
  }
  .PostContent {
    padding: 5% 2%;
  }

  .WriterDetails {
    padding: 10px 0 10px 0;
  }
  .WriterDetails p {
    font-size: 14px;
  }
  .PostText h1,
  h2,
  h3,
  b,
  i {
    letter-spacing: 1px;
    font-size: 20px;
    padding: 10px 0 5px 0;
  }
  .PostText blockquote {
    padding: 5px 0 5px 10px;
    border-left: 3px solid var(--green);
  }
  .PostContent h1 {
    font-size: 30px;
    letter-spacing: 1px;
  }
}
