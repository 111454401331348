.Footer {
  width: 100%;
  height: 15vh;
  padding-top: 20px;
  font-family: var(--secondary-font);
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.FooterIcons {
  display: flex;
  font-size: 25px;
  justify-content: space-around;
  width: 90%;
}
@media screen and (min-width: 850px) {
  .FooterIcons {
    display: none;
  }
}
.Footer a {
  text-decoration: none;
  color: var(--light-slate);
  font-weight: lighter;
  padding: 10px 0;
}
.Footer a:hover {
  color: var(--green);
}
