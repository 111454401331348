.PageNotFound {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PageNotFound > div {
  width: 40%;
  height: 40vh;
}
.PageNotFound > div > img {
  width: 100%;
  height: 100%;
}
.PageNotFound a {
  text-decoration: none;
}
