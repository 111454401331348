.BlogSection {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 12vh 13% 0 13%;
}

.Blogs {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  padding: 30px 0;
}
@media screen and (max-width: 850px) {
  .BlogSection {
    padding: 10vh 2% 0 2% !important;
  }

  .Blogs {
    grid-template-columns: 1fr;
  }
}
