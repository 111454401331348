.LoaderSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.Loader {
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
}
.object {
  width: 5px;
  height: 40px;
  background-color: #fff;
  margin: 0 3px;
  border-radius: 10px;
  animation: loading 0.8s infinite;
}
.object:nth-child(2) {
  animation-delay: 0.1s;
}
.object:nth-child(3) {
  animation-delay: 0.2s;
}
.object:nth-child(4) {
  animation-delay: 0.3s;
}
.object:nth-child(5) {
  animation-delay: 0.4s;
}
.object:nth-child(6) {
  animation-delay: 0.5s;
}
.object:nth-child(7) {
  animation-delay: 0.6s;
}
.object:nth-child(8) {
  animation-delay: 0.7s;
}
@keyframes loading {
  0% {
    height: 0;
  }
  50% {
    height: 40px;
  }
  100% {
    height: 0;
  }
}
