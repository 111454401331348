/* ::-webkit-scrollbar {
  width: 12px;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, #1468cd);
  background: gray;
  border-radius: 3px;
} */
body {
  font-family: var(--primary-font);
  background-color: var(--navy-color);
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
