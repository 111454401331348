.Sidebar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.SidebarExtraSpace {
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.SidebarContent {
  width: 50%;
  height: 100%;
  background-color: var(--light-navy);
}
.crossContent {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.crossContent > .crossSign {
  width: auto;
  height: auto;
  margin-top: 2%;
  margin-right: 1%;
  display: flex;
  font-size: 24px;
  color: var(--green);
  cursor: pointer;
}

.contentLinks {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentLinks > div {
  width: 70%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.contentLinks a {
  font-size: 19px;
  text-decoration: none;
  color: var(--lightest-slate);
  font-family: var(--secondary-font);
}
.contentLinksNumber {
  font-size: 16px;
  color: var(--green);
  display: block;
  text-align: center;
}
.activeLink {
  border-bottom: 1px solid var(--green);
}
@media screen and (min-width: 850px) {
  .Sidebar {
    display: none;
  }
}
