:root {
  --navy-color: #0a192f;
  --light-navy: #172a45;
  --lightest-navy: #303c55;
  --slate: #9f998f;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --black: #000;
  --light-black: #1e272e;
  --primary-font: "Roboto", sans-serif;
  --secondary-font: "Roboto Mono", monospace;
  --tertiary-font: "Dancing Script", cursive;
}

/* .light {
  color: #000;
  background-color: gray;
  min-height: 100vh;
}
.dark {
  background-color: var(--bg-dark-primary);
  color: var(--color-dark-primary);
  min-height: 100vh;
} */
