.iconsDiv {
  width: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 12vh;
  left: 2%;
  position: fixed;
}

.iconsLine {
  width: 50%;
  height: 32%;
  border-right: 1px solid var(--lightest-navy);
}
.socialIcons {
  width: 100%;
  height: 36%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.socialIcons a {
  color: var(--light-slate);
}
.socialIcons a:hover {
  color: var(--green);
}
.socialIcons div {
  font-size: 20px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.socialIcons div:hover {
  animation-name: moveIcon;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}
@keyframes moveIcon {
  0% {
    padding: 10px 0;
  }
  100% {
    padding: 4px 0px 5px 0;
    font-size: 29px;
  }
}

.emailDiv {
  width: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: 12vh;
  right: 2%;
  position: fixed;
}
.emailLine {
  width: 50%;
  height: 32%;
  border-right: 1px solid var(--lightest-navy);
}

.emailDiv > a {
  writing-mode: tb-rl;
  font-family: var(--secondary-font);
  color: var(--light-slate);
  display: flex;
  align-items: center;
  font-weight: lighter;
  font-size: 12px;
  letter-spacing: 2px;
  margin-bottom: 17px;
}

.emailDiv > a:hover {
  animation-name: moveMail;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}
@keyframes moveMail {
  0% {
    padding-bottom: 0px;
  }
  100% {
    color: var(--green);
    padding-bottom: 3px;
  }
}

@media screen and (max-width: 850px) {
  .iconsDiv {
    display: none;
  }
  .emailDiv {
    display: none;
  }
}
