.AboutPage {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AboutContent {
  width: 100%;
  height: 80%;
  display: flex;
}
.AboutContent > .AboutText {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.AboutText > .text {
  line-height: 25px;
  font-size: 19px;
  padding: 4% 0;
}
.AboutText > .text > p {
  padding: 6px 0;
  font-weight: light;
  color: var(--light-slate);
  font-size: 18px;
}
.AboutText > .text > p > a {
  text-decoration: none;
  color: var(--light-slate);
}
.AboutText > .text > p > a:hover {
  opacity: 0.7;
}
.AboutText > .TechStack {
  line-height: 25px;
  font-size: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 5px;
  font-family: var(--secondary-font);
}

.AboutContent > .AboutImage {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AboutImage > .actualImage {
  width: 275px;
  border-radius: 5px;
  background-color: var(--light-navy);
  position: relative;
}

.AboutImage > .actualImage > img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: block;
}
.AboutImage > .actualImage > .lineOverlay {
  position: absolute;
  border: 1px solid var(--green);
  width: 100%;
  height: 100%;
  left: 18px;
  top: 18px;
  border-radius: 4px;
}
@media screen and (max-width: 850px) {
  .AboutPage {
    height: auto;
  }

  .AboutContent {
    flex-direction: column;
    align-items: center;
  }
  .AboutContent > .AboutText {
    width: 100%;
  }
  .AboutText > .text {
    line-height: 23px;
    font-size: 17px;
  }
  .AboutText > .text > p {
    font-size: 17px;
  }
  .AboutText > .TechStack {
    line-height: 25px;
    font-size: 14px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 5px;
    font-family: var(--secondary-font);
  }

  .AboutContent > .AboutImage {
    width: 65%;
    margin: 25px 0;
  }
}
